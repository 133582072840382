import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Form,
  Select,
  InputNumber,
  notification,
  DatePicker,
} from 'antd'
import dayjs from 'dayjs'
import apiClient from '../../../services/apiClient'

const RevenueForm = ({ revenue }) => {
  const [api, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  useEffect(() => {
    if (revenue.invoiceDueDate) {
      revenue.invoiceDueDate = dayjs(revenue.invoiceDueDate, 'YYYY-MM-DD')
    }
    form.setFieldsValue({
      ...revenue,
    })
  }, [revenue, form])

  const onFinish = async values => {
    try {
      await apiClient.put(`/revenues/${revenue._id}`, values)
      api.success({
        message: 'Ingreso actualizado correctamente',
        placement: 'topRight',
        description: 'Redireccionando al listado de ingresos...',
        duration: 3,
        onClose: () => navigate(-1),
      })
    } catch (error) {
      console.error(error)
      api.error({
        message: 'Error al actualizar el ingreso',
        placement: 'topRight',
        description: 'Intente de nuevo...',
        duration: 3,
      })
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        wrapperCol={{ span: 8 }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Moneda"
          name="currency"
          rules={[{ required: true, message: 'Por favor ingrese la moneda' }]}
        >
          <Select
            placeholder="Moneda"
            allowClear
            options={[
              { value: 'UF', label: 'UF' },
              { value: 'USD', label: 'USD' },
              { value: 'CLP', label: 'CLP' },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Unidades vendidas"
          name="unitsSold"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese la cantidad de unidades vendidas',
            },
          ]}
        >
          <InputNumber addonBefore={'#'} min={0} />
        </Form.Item>

        <Form.Item
          label="Fecha de facturación planificada"
          name="invoiceDueDate"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese la fecha de facturación planificada',
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default RevenueForm
