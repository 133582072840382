import { Button, Card, Flex, Form, Input, Select, notification } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../services/apiClient'
import ClientSelect from '../../components/ClientSelect'
import DrimerSelect from '../../components/DrimerSelect'

const ProjectCreate = () => {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [api, contextHolder] = notification.useNotification()

  const navigate = useNavigate()

  const onFinish = async values => {
    setErrors([])
    setLoading(true)
    try {
      await apiClient.post('/projects', values)
      api.success({
        message: 'Proyecto creado correctamente',
        description: 'Redireccionando a la lista de proyectos...',
        placement: 'topRight',
        duration: 3,
        onClose: () => navigate('/projects'),
      })
      setLoading(false)
    } catch (error) {
      setErrors(error.response.data.errors)
      api.error({
        message: 'Error al crear el proyecto',
        description: 'Por favor, revisa el formulario',
        placement: 'topRight',
        duration: 3,
      })
      console.error(error)
      setLoading(false)
    }
  }

  const fieldWithError = field => {
    if (errors.some(error => error.field === field)) {
      return {
        help: errors
          .map(error => error.field === field && error.message)
          .filter(Boolean)[0],
        validateStatus: 'error',
      }
    }
  }

  return (
    <Card>
      {contextHolder}
      <Flex justify="space-between" align="center">
        <h1>Crear proyecto</h1>
        <Button type="primary" onClick={() => navigate('/projects')}>
          Volver
        </Button>
      </Flex>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Nombre"
          name="name"
          {...fieldWithError('name')}
          required
        >
          <Input placeholder="Nombre del proyecto" />
        </Form.Item>
        <Form.Item
          label="Cliente"
          name="client"
          {...fieldWithError('client')}
          required
        >
          <ClientSelect />
        </Form.Item>
        <Form.Item
          label="Responsable"
          name="responsible"
          tooltip="Project Manager responsable del proyecto"
          {...fieldWithError('responsible')}
          required
        >
          <DrimerSelect />
        </Form.Item>
        <Form.Item
          label="Supervisor"
          name="supervisor"
          tooltip="El Partner o Project Manager Senior que supervisa el proyecto"
          {...fieldWithError('supervisor')}
          required
        >
          <DrimerSelect />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="type"
          {...fieldWithError('type')}
          required
        >
          <Select
            options={[
              { label: 'Times & Materials', value: 'TIMES_AND_MATERIALS' },
              { label: 'Fixed Scope', value: 'FIXED_SCOPE' },
              { label: 'Discovery', value: 'DISCOVERY' },
              { label: 'Support', value: 'SUPPORT' },
              { label: 'Staffing', value: 'STAFFING' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Crear proyecto
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default ProjectCreate
