import { GoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'antd'

const { Content } = Layout

const SignIn = () => {
  const navigate = useNavigate()

  const onSuccess = async credentialResponse => {
    localStorage.setItem('authToken', credentialResponse.credential)
    navigate('/')
  }

  return (
    <>
      <Layout
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Content
          style={{
            marginTop: '200px',
            padding: '24px',
            backgroundColor: '#fff',
            width: '400px',
            display: 'flex',
            justifyContent: 'center',
            maxHeight: '200px',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1>Sign In</h1>
            <GoogleLogin
              onSuccess={onSuccess}
              onError={() => {
                console.log('Login Failed')
              }}
            />
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default SignIn
