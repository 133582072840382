import {
  Card,
  Col,
  Button,
  DatePicker,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  notification,
} from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import ClientSelect from '../../../components/ClientSelect'
import ProjectSelect from '../../../components/ProjectSelect'

const InvoiceCreate = () => {
  const [errors, setErrors] = useState([])
  const [api, contextHolder] = notification.useNotification()
  const [selectedClient, setSelectedClient] = useState(null)

  const navigate = useNavigate()

  const onFinish = async values => {
    try {
      setErrors([])
      await apiClient.post('/revenues', values)
      api.success({
        message: 'Factura creada correctamente',
        description: 'Redireccionando a la lista de facturas...',
        placement: 'topRight',
        duration: 3,
        onClose: () => navigate('/revenues'),
      })
    } catch (error) {
      api.error({
        message: 'Error al crear la factura',
        description: 'Intente de nuevo...',
        placement: 'topRight',
        duration: 3,
      })
      if (error.response) {
        setErrors(error.response.data.errors)
      } else {
        console.error(error)
      }
    }
  }

  const fieldWithError = field => {
    if (errors.some(error => error.field === field)) {
      return {
        help: errors
          .map(error => error.field === field && error.message)
          .filter(Boolean)[0],
        validateStatus: 'error',
      }
    }
  }

  const handleClientChange = value => {
    setSelectedClient(value)
  }

  return (
    <>
      {contextHolder}

      <Row>
        <Col span={12} offset={6}>
          <Card>
            <Flex align="center" justify="space-between">
              <h1>Crear un nuevo ingreso</h1>
              <Button onClick={() => navigate(-1)}>Volver</Button>
            </Flex>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={'optional'}
              initialValues={{ status: 'TO_INVOICE' }}
            >
              <Form.Item
                label="Estado"
                name="status"
                required
                tooltip={{
                  title:
                    'Estado inicial de la factura. Por defecto, todas las facturas se crean en estado "Por facturar".',
                }}
                {...fieldWithError('client')}
              >
                <Select
                  disabled
                  options={[{ value: 'TO_INVOICE', label: 'Por facturar' }]}
                />
              </Form.Item>
              <Form.Item
                label="Cliente"
                name="client"
                required
                {...fieldWithError('client')}
              >
                <ClientSelect onChange={handleClientChange} />
              </Form.Item>
              <Form.Item
                label="Proyecto"
                name="project"
                required
                {...fieldWithError('project')}
              >
                <ProjectSelect client={selectedClient} />
              </Form.Item>
              <Form.Item
                label="Moneda"
                required
                tooltip={{
                  title: 'Moneda en la cuál se acordó la venta.',
                }}
                name="currency"
                {...fieldWithError('currency')}
              >
                <Select
                  options={[
                    { value: 'UF', label: 'UF' },
                    { value: 'USD', label: 'USD' },
                    { value: 'CLP', label: 'CLP' },
                  ]}
                />
              </Form.Item>{' '}
              <Form.Item
                label="Unidades vendidas"
                required
                tooltip={{
                  title:
                    'Cantidad de unidades vendidas. Por ejemplo, si se vendieron 10 UF de un proyecto, la cantidad de unidades vendidas es 10.',
                }}
                name="unitsSold"
                {...fieldWithError('unitsSold')}
              >
                <InputNumber addonBefore={'#'} />
              </Form.Item>
              <Form.Item
                label="Fecha de facturación planificada"
                required
                tooltip={{
                  title: 'Fecha en la que la factura debe ser emitida.',
                }}
                name="invoiceDueDate"
                {...fieldWithError('invoiceDueDate')}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                <Button type="primary" htmlType="submit" block>
                  Crear factura
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default InvoiceCreate
