import { useEffect, useState } from 'react'
import { Button, Card, Flex } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../../services/apiClient'

import ClientTable from './ClientTable'

const ClientList = () => {
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true)
      try {
        const { data } = await apiClient.get('/clients')
        const clients = data.map(client => ({
          ...client,
          key: client._id,
        }))
        setClients(clients)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchClients()
  }, [])

  const navigate = useNavigate()

  return (
    <Card>
      <Flex align="center" justify="space-between">
        <h1>Clientes</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate('create')}
        >
          Crear Cliente
        </Button>
      </Flex>
      <ClientTable
        clients={clients}
        loading={loading}
        setClients={setClients}
      />
    </Card>
  )
}

export default ClientList
