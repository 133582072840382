import { Button, Modal, Table, Tag } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useState } from 'react'

import apiClient from '../../../services/apiClient'

const RevenueTable = ({ revenues, setRevenues, loading }) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [revenue, setRevenue] = useState({})

  const handleOk = async id => {
    setConfirmLoading(true)
    try {
      await apiClient.delete(`/revenues/${id}`)
      const newRevenues = revenues.filter(revenue => revenue._id !== id)
      setRevenues(newRevenues)
      setOpen(false)
      setConfirmLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'Número de factura',
      key: 'invoiceNumber',
      render: revenue => (
        <a href={`/revenues/${revenue._id}`}>
          {revenue?.invoiceInformation?.invoiceNumber ?? 'No emitida'}
        </a>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: status => <Tag color={status.color}>{status.text}</Tag>,
    },
    {
      title: 'Cliente',
      dataIndex: ['project', 'client', 'name'],
      key: 'client',
    },
    {
      title: 'Proyecto',
      dataIndex: ['project', 'name'],
      key: 'project',
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Unidades',
      dataIndex: 'unitsSold',
      key: 'unitsSold',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_text, record) => (
        <>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => {
              setConfirmLoading(false)
              setRevenue(record)
              setOpen(true)
            }}
            danger
          >
            Eliminar
          </Button>
        </>
      ),
    },
  ]

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Table columns={columns} dataSource={revenues} loading={loading} />
      <Modal
        title={`Eliminar factura ${revenue._id}`}
        open={open}
        onOk={() => handleOk(revenue._id)}
        okText="Eliminar factura"
        cancelText="Cancelar"
        okType="danger"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>¿Estás seguro que deseas eliminar la factura?</p>
      </Modal>
    </>
  )
}

export default RevenueTable
