import { useEffect } from 'react'
import { Button, Form, Select, notification } from 'antd'
import { useState } from 'react'

import apiClient from '../../../../services/apiClient'

const AccountingInformation = ({ revenue }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    form.setFieldsValue({
      accruedMonth: revenue?.accountingInformation?.accruedMonth,
      accruedYear: revenue?.accountingInformation?.accruedYear,
      businessUnit: revenue?.accountingInformation?.businessUnit,
    })
  }, [revenue, form])

  const onFinish = async values => {
    setLoading(true)
    try {
      await apiClient.post(
        `/revenues/${revenue.id}/update-accounting-information`,
        values
      )
      api.success({
        message: 'Información contable actualizada',
      })
    } catch (error) {
      console.log('Failed to update accounting information', error)
      api.error({
        message: 'Algo salió mal',
        description: error.message,
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      {contextHolder}
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Mes devengado"
          name="accruedMonth"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el mes devengado',
            },
          ]}
        >
          <Select
            options={[
              { label: 'Enero', value: 1 },
              { label: 'Febrero', value: 2 },
              { label: 'Marzo', value: 3 },
              { label: 'Abril', value: 4 },
              { label: 'Mayo', value: 5 },
              { label: 'Junio', value: 6 },
              { label: 'Julio', value: 7 },
              { label: 'Agosto', value: 8 },
              { label: 'Septiembre', value: 9 },
              { label: 'Octubre', value: 10 },
              { label: 'Noviembre', value: 11 },
              { label: 'Diciembre', value: 12 },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Año devengado"
          name="accruedYear"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese el año devengado',
            },
          ]}
        >
          <Select
            options={[
              { label: '2021', value: 2021 },
              { label: '2022', value: 2022 },
              { label: '2023', value: 2023 },
              { label: '2024', value: 2024 },
              { label: '2025', value: 2025 },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Unidad de negocio"
          name="businessUnit"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese la unidad de negocio',
            },
          ]}
        >
          <Select
            options={[
              { label: 'Staffing', value: 'STAFFING' },
              { label: 'Software Factory', value: 'SOFTWARE_FACTORY' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default AccountingInformation
