import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Result
      status="404"
      title="404"
      subTitle="Ups, esta página no existe."
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          Voler al menu
        </Button>
      }
    />
  )
}

export default NotFound
