import { Outlet, useNavigate } from 'react-router-dom'
import { Avatar, Flex, Layout, Menu, theme } from 'antd'
import { useEffect } from 'react'
import {
  DownloadOutlined,
  FileTextOutlined,
  HomeOutlined,
  RocketOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useState } from 'react'

import apiClient from '../services/apiClient.js'

const { Content, Header, Sider } = Layout

const Root = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [currentUser, setCurrentUser] = useState({})

  const navigate = useNavigate()
  useEffect(() => {
    const getCurrentUser = async () => {
      const token = localStorage.getItem('authToken')
      if (!token) {
        navigate('/sign-in')
      }

      const { data } = await apiClient.get('/current-user')
      setCurrentUser(data.currentUser)
    }

    getCurrentUser()
  }, [navigate])

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  const items = [
    {
      label: 'Menu',
      path: '/',
      icon: <HomeOutlined />,
      onClick: () => navigate('/'),
    },
    {
      label: 'Ingresos',
      path: 'revenues',
      icon: <FileTextOutlined />,
      onClick: () => navigate('revenues'),
    },
    {
      label: 'Clientes',
      path: 'clients',
      icon: <StarOutlined />,
      onClick: () => navigate('clients'),
    },
    {
      label: 'Proyectos',
      path: 'projects',
      icon: <RocketOutlined />,
      onClick: () => navigate('projects'),
    },
    {
      label: 'Drimers',
      path: 'drimers',
      icon: <UserOutlined />,
      onClick: () => navigate('drimers'),
    },
    {
      label: 'Exportadores',
      path: 'exporters',
      icon: <DownloadOutlined />,
      onClick: () => navigate('exporters'),
    },
  ]

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        style={{
          minHeight: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Header
          style={{
            background: colorBgContainer,
          }}
        >
          <Flex justify="flex-end" align="center" style={{ height: '100%' }}>
            <p style={{ marginRight: 20 }}>
              {currentUser.name} -- {currentUser.email}
            </p>
            <Avatar
              size="large"
              src="https://hips.hearstapps.com/hmg-prod/images/tom-cruise-attends-the-european-premiere-of-rock-of-ages-at-news-photo-1647854690.jpg?crop=1xw:0.84544xh;center,top"
            />
          </Flex>
        </Header>
        <Content
          style={{
            margin: '0px 16px 0px',
          }}
        >
          <div
            style={{
              marginTop: 20,
              minHeight: 360,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Root
