import { useState } from 'react'
import { Button, Modal, Table, notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import apiClient from '../../../services/apiClient'

const DrimerTable = ({ drimers, setDrimers }) => {
  const [drimer, setDrimer] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification()

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Borrar',
      render: (text, record) => (
        <Button
          type="primary"
          onClick={() => {
            setConfirmLoading(false)
            setDrimer(record)
            setIsModalOpen(true)
          }}
          icon={<DeleteOutlined />}
          danger
        />
      ),
    },
  ]

  const handleCancel = () => {
    setIsModalOpen(false)
    setDrimer(null)
  }

  const handleOk = async id => {
    setConfirmLoading(true)
    try {
      await apiClient.delete(`/drimers/${id}`)
      setDrimers(drimers.filter(drimer => drimer._id !== id))
      api.success({
        message: 'Drimer eliminado',
        description: `El drimer ${drimer.name} ha sido eliminado`,
        duration: 2,
      })
    } catch (error) {
      console.error('DrimerTable -> handleOk', error)
    } finally {
      setIsModalOpen(false)
      setDrimer(null)
      setConfirmLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <Table dataSource={drimers} columns={columns} />
      <Modal
        title="Eliminar Drimer"
        open={isModalOpen}
        onOk={() => handleOk(drimer._id)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>¿Estás seguro que quieres eliminar el drimer {drimer?.name}?</p>
      </Modal>
    </>
  )
}

export default DrimerTable
