import { Button, Card, Form, Input, Space, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import ClientInformation from './ClientInformation'

const ClientDetail = () => {
  const [client, setClient] = useState({})
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const [api, contextHolder] = notification.useNotification()

  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchClient = async id => {
      try {
        const { data } = await apiClient.get(`/clients/${id}`)
        setClient(data)
        form.setFieldsValue(data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchClient(id)
  }, [id, form])

  const onFinish = async values => {
    setLoading(true)
    try {
      await apiClient.put(`/clients/${id}`, values)
      setLoading(false)
      api.success({
        message: 'Cliente actualizado correctamente',
        description: 'Redireccionando a la lista de clientes...',
        placement: 'topRight',
        duration: 3,
        onClose: () => navigate('/clients'),
      })
    } catch (error) {
      api.error({
        message: 'Error al actualizar el cliente',
        description: 'Intente nuevamente...',
        placement: 'topRight',
      })
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%', padding: '20px' }}
      >
        <Card
          title="Detalle de Cliente"
          extra={
            <Button type="default" onClick={() => navigate('/clients')}>
              Volver
            </Button>
          }
        >
          <ClientInformation client={client} />
        </Card>
        <Card title="Actualizar Cliente">
          <Form layout="vertical" onFinish={onFinish} form={form}>
            <Card
              type="inner"
              title="Contraparte de facturación"
              style={{ marginTop: 20 }}
            >
              <Form.Item
                label="Correo Electrónico"
                name={['invoicingCounterpart', 'email']}
                required
              >
                <Input placeholder="correo@ejemplo.com" />
              </Form.Item>
              <Form.Item
                label="Nombre"
                name={['invoicingCounterpart', 'name']}
                required
              >
                <Input />
              </Form.Item>
            </Card>

            <Card
              type="inner"
              title="Contraparte de cobranza"
              style={{ marginTop: 20 }}
            >
              <Form.Item
                label="Correo Electrónico"
                name={['collectionCounterpart', 'email']}
                required
              >
                <Input placeholder="correo@ejemplo.com" />
              </Form.Item>
              <Form.Item
                label="Nombre"
                name={['collectionCounterpart', 'name']}
                required
              >
                <Input />
              </Form.Item>
            </Card>
            <Card type="inner" title="Comentario" style={{ marginTop: 20 }}>
              <Form.Item name="comment">
                <Input.TextArea />
              </Form.Item>
            </Card>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Actualizar cliente
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
    </>
  )
}

export default ClientDetail
