import { Select } from 'antd'
import { useEffect, useState } from 'react'

import apiClient from '../services/apiClient'

const DrimerSelect = ({ onChange = () => {} }) => {
  const [drimers, setDrimers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchDrimers = async () => {
      setLoading(true)
      try {
        const { data } = await apiClient.get('/drimers')
        const drimers = data.map(drimer => ({
          label: drimer.name,
          value: drimer._id,
        }))
        setDrimers(drimers)
        console.log('race #2')
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
    fetchDrimers()
  }, [])

  return (
    <Select
      placeholder="Selecciona un drimer"
      loading={loading}
      options={drimers}
      onChange={onChange}
    />
  )
}

export default DrimerSelect
