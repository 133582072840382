import { Button, Card, Descriptions, Space, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import UpdateForm from './UpdateForm'
import RevenuesTable from './RevenuesTable'

const ProjectDetail = () => {
  const [project, setProject] = useState({})
  const [api, contextHolder] = notification.useNotification()

  const { id: projectId } = useParams()

  useEffect(() => {
    const fetchInformation = async id => {
      try {
        const { data } = await apiClient.get(`/projects/${id}`)
        setProject(data)
      } catch (error) {
        console.log(error)
        api.error({
          message: 'Error al cargar la información',
          description: 'Ocurrió un error al cargar la información',
          duration: 2,
        })
      }
    }
    fetchInformation(projectId)
  }, [projectId, api])

  const navigate = useNavigate()

  return (
    <Space
      direction="vertical"
      style={{
        display: 'flex',
      }}
      size="large"
    >
      <Card
        title="Detalle de proyecto"
        extra={
          <Button type="default" onClick={() => navigate('/projects')}>
            Volver
          </Button>
        }
      >
        {contextHolder}

        <Descriptions columns={6} bordered>
          <Descriptions.Item label="Cliente">
            {project.client?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Nombre">{project.name}</Descriptions.Item>
          <Descriptions.Item label="Responsable">
            {project.responsible?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Supervisor">
            {project.supervisor?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo">{project.type}</Descriptions.Item>
        </Descriptions>
      </Card>
      <Card title="Facturas">
        <RevenuesTable />
      </Card>
      <Card title="Editar proyecto">
        <UpdateForm project={project} />
      </Card>
    </Space>
  )
}

export default ProjectDetail
