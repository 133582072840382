import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import ClientCreate from '../views/Client/ClientCreate'
import ClientDetail from '../views/Client/ClientDetail'
import ClientList from '../views/Client/ClientList'
import DrimerList from '../views/Drimer/DrimerList'
import DrimerCreate from '../views/Drimer/DrimerCreate'
import Home from '../views/Home'
import RevenueCreate from '../views/Revenue/RevenueCreate'
import RevenueDetail from '../views/Revenue/RevenueDetail'
import RevenueList from '../views/Revenue/RevenueList'
import PrivateRoute from '../components/PrivateRoute'
import ProjectCreate from '../views/Project/ProjectCreate'
import ProjectDetail from '../views/Project/ProjectDetail'
import ProjectList from '../views/Project/ProjectList'
import Root from '../views/Root'
import SignIn from '../views/SignIn'
import SignOut from '../views/SignOut'
import NotFound from '../views/NotFound'
import Exporter from '../views/Exporter'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Root />}>
        <Route element={<PrivateRoute />}>
          <Route path="" element={<Home />} />
          <Route path="revenues">
            <Route path="" element={<RevenueList />} />
            <Route path="create" element={<RevenueCreate />} />
            <Route path=":id" element={<RevenueDetail />} />
          </Route>
          <Route path="clients">
            <Route path="" element={<ClientList />} />
            <Route path="create" element={<ClientCreate />} />
            <Route path=":id" element={<ClientDetail />} />
          </Route>
          <Route path="drimers">
            <Route path="" element={<DrimerList />} />
            <Route path="create" element={<DrimerCreate />} />
          </Route>
          <Route path="projects">
            <Route path="" element={<ProjectList />} />
            <Route path="create" element={<ProjectCreate />} />
            <Route path=":id" element={<ProjectDetail />} />
          </Route>
          <Route path="exporters" element={<Exporter />} />
        </Route>
      </Route>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-out" element={<SignOut />} />
      <Route path="*" element={<NotFound />} />
    </>
  )
)

const Routes = () => {
  return <RouterProvider router={router} />
}

export default Routes
