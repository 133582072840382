import { googleLogout } from '@react-oauth/google'
import { Navigate } from 'react-router-dom'

const SignOut = () => {
  googleLogout()
  localStorage.removeItem('authToken')

  return <Navigate to="/sign-in" />
}

export default SignOut
