import { useState } from 'react'
import { Descriptions, Badge } from 'antd'
import numeral from 'numeral'
import moment from 'moment'

import parseInvoiceStatus from '../../../utils/parseInvoiceStatus'
import { useEffect } from 'react'

const RevenueDescriptions = ({ revenue: rawRevenue }) => {
  const [revenue, setRevenue] = useState({})
  useEffect(() => {
    setRevenue(formatRevenue(rawRevenue))
  }, [rawRevenue])

  const formatRevenue = revenue => {
    return {
      ...revenue,
      netValue: numeral(revenue.netValue).format('$0,0'),
      tax: numeral(revenue.tax).format('$0,0'),
      grossValue: numeral(revenue.grossValue).format('$0,0'),
      realPaymentDate: revenue.realPaymentDate
        ? moment(revenue.realPaymentDate, 'YYYY-MM-DD')
        : null,
    }
  }
  const items = [
    {
      label: 'Cliente',
      children: revenue.project?.client?.name,
      span: 2,
    },

    {
      label: 'Proyecto',
      children: revenue.project?.name,
      span: 2,
    },
    {
      label: 'Estado',
      children: (
        <Badge
          status="processing"
          text={revenue?.status && parseInvoiceStatus(revenue.status).text}
        />
      ),
      span: 4,
    },
    {
      label: 'Moneda',
      children: revenue.currency,
      span: 2,
    },
    {
      label: 'Unidades vendidas',
      children: revenue.unitsSold,
    },
    {
      label: 'Fecha de facturación planificada',
      children: revenue.invoiceDueDate,
    },
  ]

  return (
    <div>
      <Descriptions column={4} bordered items={items} />
    </div>
  )
}

export default RevenueDescriptions
