import { useEffect, useState } from 'react'
import { FileAddOutlined } from '@ant-design/icons'
import { Button, Card, Flex } from 'antd'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import ProjectTable from './ProjectTable'

const ProjectList = () => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = async () => {
    try {
      const response = await apiClient.get('/projects')
      setProjects(response.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Card>
      <Flex align="center" justify="space-between">
        <h1>Proyectos</h1>
        <Button
          icon={<FileAddOutlined />}
          type="primary"
          onClick={() => navigate('create')}
        >
          Crear Proyecto
        </Button>
      </Flex>
      <ProjectTable
        projects={projects}
        loading={loading}
        setProjects={setProjects}
      />
    </Card>
  )
}

export default ProjectList
