import { Descriptions } from 'antd'

const ClientInformation = ({ client }) => {
  return (
    <Descriptions column={6} bordered>
      <Descriptions.Item label="Nombre" span={3}>
        {client.name}
      </Descriptions.Item>
      <Descriptions.Item label="Rut" span={3}>
        {client.rut}
      </Descriptions.Item>
      <Descriptions.Item label="Contraparte de facturación" span={6}>
        {client?.invoicingCounterpart?.name} -{' '}
        {client?.invoicingCounterpart?.email}
      </Descriptions.Item>
      <Descriptions.Item label="Contraparte de facturación" span={6}>
        {client?.collectionCounterpart?.name} -{' '}
        {client?.collectionCounterpart?.email}
      </Descriptions.Item>
      <Descriptions.Item label="Comentario" span={6}>
        {client.comment ? client.comment : 'Sin comentario'}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default ClientInformation
