import { Button, notification } from 'antd'
import { useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import apiClient from '../services/apiClient'

const Exporter = () => {
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  const handleClick = async () => {
    setLoading(true)

    try {
      const { data } = await apiClient.get('/invoices/export')

      // Download the file
      const url = window.URL.createObjectURL(new Blob([data]))
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', 'facturas.csv')
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      window.URL.revokeObjectURL(url)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      api.error({
        message: 'Error al intentar exportar las facturas',
        placement: 'topRight',
        description: 'Intente de nuevo...',
        duration: 3,
      })
      console.log(error)
    }
  }

  return (
    <>
      {contextHolder}
      <h1>Exportadores</h1>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={handleClick}
        loading={loading}
      >
        Exportar facturas
      </Button>
    </>
  )
}

export default Exporter
