import { Tag } from 'antd'

const parseInvoiceStatus = status => {
  if (!status) throw new Error('Status is required')

  status = status.toUpperCase()

  const statusMap = {
    PAID: {
      text: 'PAGADA',
      color: 'green',
    },
    PENDING_PAYMENT: {
      text: 'PAGO PENDIENTE',
      color: 'red',
    },
    OVERDUE: {
      text: 'EN MORA',
      color: 'green',
    },
    TO_INVOICE: {
      text: 'POR FACTURAR',
      color: '',
    },
    FACTORING: {
      text: 'FACTORIZADA',
      color: 'red',
    },
  }
  const { text, color } = statusMap[status]
  return {
    text,
    color,
    renderTag: function () {
      return <Tag color={color}>{text}</Tag>
    },
  }
}

export default parseInvoiceStatus
