import { useEffect, useState } from 'react'
import { Select } from 'antd'

import apiClient from '../services/apiClient'

const ClientSelect = ({ onChange = () => {} }) => {
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true)
      try {
        const { data } = await apiClient.get('/clients')
        const clients = data.map(client => ({
          ...client,
          key: client._id,
        }))
        setClients(clients)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchClients()
  }, [])

  const options = clients.map(client => ({
    label: client.name,
    value: client._id,
  }))

  return (
    <Select
      placeholder="Selecciona un cliente"
      loading={loading}
      options={options}
      onChange={onChange}
    />
  )
}

export default ClientSelect
