import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

const PrivateRoute = () => {
  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken')
    return token != null
  }

  return isAuthenticated() ? <Outlet /> : <Navigate to="/sign-in" />
}

export default PrivateRoute
