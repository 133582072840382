import { useState } from 'react'
import { Button, Modal, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import apiClient from '../../../services/apiClient'

const ProjectTable = ({ loading, projects, setProjects }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [project, setProject] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const columns = [
    {
      title: 'Nombre',
      key: 'name',
      render: project => (
        <a href={`/projects/${project._id}`}>{project.name}</a>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Cliente',
      dataIndex: ['client', 'name'],
      key: 'client',
    },
    {
      title: 'Responsable',
      dataIndex: ['responsible', 'name'],
      key: 'responsible',
    },
    {
      title: 'Supervisor',
      dataIndex: ['supervisor', 'name'],
      key: 'supervisor',
    },
    {
      title: 'Eliminar',
      key: 'delete',
      render: project => (
        <Button
          type="primary"
          onClick={() => {
            setConfirmLoading(false)
            setProject(project)
            setIsModalOpen(true)
          }}
          danger
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ]

  const handleOk = async id => {
    setConfirmLoading(true)
    try {
      await apiClient.delete(`/projects/${id}`)
      const newProjects = projects.filter(project => project._id !== id)
      setProjects(newProjects)
      setIsModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setProject(null)
  }

  return (
    <>
      <Table
        dataSource={projects}
        loading={loading}
        rowKey="_id"
        columns={columns}
      />
      <Modal
        title="Eliminar Proyecto"
        open={isModalOpen}
        onOk={() => handleOk(project._id)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>¿Está seguro que desea eliminar el proyecto {project?.name}?</p>
      </Modal>
    </>
  )
}

export default ProjectTable
