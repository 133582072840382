import { Button, Card, Flex } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import DrimerTable from './DrimerTable'

const DrimerList = () => {
  const [drimers, setDrimers] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    const fetchDrimers = async () => {
      try {
        const { data } = await apiClient.get('/drimers')
        const drimers = data.map(drimer => ({
          ...drimer,
          key: drimer._id,
        }))
        setDrimers(drimers)
      } catch (error) {
        console.error('DrimerList -> fetchDrimers', error)
      }
    }
    fetchDrimers()
  }, [])

  return (
    <Card>
      <Flex justify="space-between" align="center">
        <h1>Drimers</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate('create')}
        >
          Crear Drimer
        </Button>
      </Flex>
      <DrimerTable drimers={drimers} setDrimers={setDrimers} />
    </Card>
  )
}

export default DrimerList
