import { Table } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import apiClient from '../../../services/apiClient'
import parseInvoiceStatus from '../../../utils/parseInvoiceStatus'

const RevenuesTable = () => {
  const [revenues, setRevenues] = useState([])
  const [loading, setLoading] = useState(false)
  const { id: projectId } = useParams()

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true)
      try {
        const { data } = await apiClient.get(`/revenues?project=${projectId}`)
        const revenues = data.map(revenue => ({
          ...revenue,
          key: revenue._id,
          status: parseInvoiceStatus(revenue.status),
        }))
        setRevenues(revenues)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    fetchInvoices()
  }, [projectId])

  const columns = [
    {
      title: 'Número de factura',
      key: 'invoiceNumber',
      render: revenue => (
        <a href={`/revenues/${revenue._id}`}>
          {revenue.invoiceNumber ?? 'No emitida'}
        </a>
      ),
    },
    {
      title: 'Estado',
      key: 'status',
      render: ({ status }) => {
        return status?.renderTag()
      },
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
    },
    {
      title: 'Unidades',
      dataIndex: 'unitsSold',
    },
  ]
  return (
    <>
      <Table columns={columns} dataSource={revenues} loading={loading} />
    </>
  )
}

export default RevenuesTable
