import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Card, Form, Space, notification } from 'antd'

import RevenueDescriptions from './RevenueDescriptions'
import InvoiceInformation from './InvoiceInformation'
import AccountingInformation from './AccountingInformation'
import apiClient from '../../../services/apiClient'
import RevenueForm from './RevenueForm'

const InvoiceDetail = () => {
  const [revenue, setRevenue] = useState({})
  const { id } = useParams()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()

  useEffect(() => {
    const fetchInformation = async () => {
      try {
        const { data: revenue } = await apiClient.get(`/revenues/${id}`)
        setRevenue(revenue)
      } catch (error) {
        api.error({
          message: 'Error al obtener la factura',
          placement: 'topRight',
          description: 'Intente de nuevo...',
          duration: 3,
        })
        console.error(error)
      }
    }

    fetchInformation()
  }, [id, form, api])

  return (
    <>
      {contextHolder}
      <Space
        direction="vertical"
        style={{
          display: 'flex',
        }}
        size="large"
      >
        <Card
          title="Información de la factura"
          extra={<Button onClick={() => navigate(-1)}>Volver</Button>}
        >
          <RevenueDescriptions revenue={revenue} />
        </Card>

        <Card title="Información de facturación">
          <InvoiceInformation revenue={revenue} />
        </Card>

        <Card title="Información de Contabilidad">
          <AccountingInformation revenue={revenue} />
        </Card>

        <Card title="Editar factura">
          <RevenueForm revenue={revenue} />
        </Card>
      </Space>
    </>
  )
}

export default InvoiceDetail
