import { useEffect, useState } from 'react'
import { Select } from 'antd'

import apiClient from '../services/apiClient'

const ProjectSelect = ({ client = null, onChange = () => {} }) => {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true)
      try {
        const response = await apiClient.get('/projects')
        setProjects(response.data)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchProjects()
  }, [])

  const options = projects
    .filter(project => (client ? project.client._id === client : true))
    .map(project => ({
      label: project.name,
      value: project._id,
    }))

  return (
    <Select
      placeholder="Selecciona un proyecto"
      loading={loading}
      options={options}
      onChange={onChange}
    />
  )
}

export default ProjectSelect
