import { Button, Card, Flex } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import numeral from 'numeral'

import apiClient from '../../../services/apiClient'
import RevenuesTable from './RevenuesTable'
import parseInvoiceStatus from '../../../utils/parseInvoiceStatus'

const RevenueList = () => {
  const [revenues, setRevenues] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchRevenues = async () => {
      setLoading(true)
      try {
        const response = await apiClient.get('/revenues', {
          headers: {
            'Cache-Control': 'no-cache',
          },
        })
        const revenues = response.data.map(revenue => ({
          ...revenue,
          grossValue: numeral(revenue.grossValue).format('$0,0'),
          status: parseInvoiceStatus(revenue.status),
          key: revenue._id,
        }))
        setRevenues(revenues)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }

    fetchRevenues()
  }, [])

  return (
    <Card>
      <Flex align="center" justify="space-between">
        <h1>Ingresos</h1>
        <Button type="primary" onClick={() => navigate('create')}>
          Crear ingreso
        </Button>
      </Flex>

      <RevenuesTable
        revenues={revenues}
        setRevenues={setRevenues}
        loading={loading}
      />
    </Card>
  )
}

export default RevenueList
