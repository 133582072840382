import { Descriptions } from 'antd'
import UpdateForm from './UpdateForm'

const InvoiceInformation = ({ revenue }) => {
  if (revenue.status === 'TO_INVOICE') {
    return <UpdateForm />
  }
  const { invoiceInformation } = revenue
  return (
    <>
      <Descriptions bordered>
        <Descriptions.Item label="Número de factura">
          {invoiceInformation?.invoiceNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de emisión">
          {invoiceInformation?.issuedDate}
        </Descriptions.Item>
        <Descriptions.Item label="Valor neto">
          {invoiceInformation?.netValue}
        </Descriptions.Item>
        <Descriptions.Item label="Impuesto">
          {invoiceInformation?.tax}
        </Descriptions.Item>
        <Descriptions.Item label="Valor bruto">
          {invoiceInformation?.grossValue}
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}

export default InvoiceInformation
