import { Button, Card, Input, Flex, Form, notification } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../services/apiClient'

const DrimerCreate = () => {
  const [loading, setLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification()

  const navigate = useNavigate()

  const onFinish = async values => {
    setLoading(true)
    try {
      await apiClient.post('/drimers', values)
      api.success({
        message: 'Drimer creado',
        description: `El drimer ${values.name} ha sido creado`,
        duration: 2,
        onClose: () => navigate('/drimers'),
      })
    } catch (error) {
      setLoading(false)
      console.error('DrimerCreate -> onFinish', error)
    }
  }

  return (
    <Card>
      {contextHolder}
      <Flex justify="space-between" align="center">
        <h1>Crear Drimer</h1>
        <Button type="primary" onClick={() => navigate('/drimers')}>
          Volver
        </Button>
      </Flex>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item label="Nombre" name="name">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Crear
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default DrimerCreate
