import { useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import { useParams, useNavigate } from 'react-router-dom'

import apiClient from '../../../../services/apiClient'

const UpdateForm = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const onFinish = async values => {
    setLoading(true)
    try {
      await apiClient.post(`/revenues/${id}/update-invoice-information`, values)
      form.resetFields()
      api.success({
        message: `Factura ${values.invoiceNumber} emitida correctamente`,
        placement: 'topRight',
        description: 'Redireccionando a la lista de facturas...',
        duration: 2,
        onClose: () => navigate(-1),
      })
      console.log('Success:', values)
    } catch (error) {
      let errorDescription = 'Intente de nuevo...'

      const apiErrors = error?.response?.data?.errors
      if (apiErrors) {
        console.log('inside if')
        errorDescription = apiErrors.reduce((acc, err) => {
          return `${acc} ${err.message}\n`
        }, '')
      }

      api.error({
        message: 'Error al intentar actualizar la factura',
        placement: 'topRight',
        description: errorDescription,
        duration: 3,
      })
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      <p>
        Con este formulario puedes vincular una factura emitida y visible desde
        Clay a un ingreso. Esto generará una llamada a Clay para actualizar la
        información en el ERP.
      </p>
      <Form layout="inline" onFinish={onFinish} form={form}>
        <Form.Item name="invoiceNumber" label="Número de factura" required>
          <Input placeholder="Número de factura" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Vincular ingreso a una factura
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UpdateForm
