import { useState } from 'react'
import { Button, Modal, Table, notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import apiClient from '../../../services/apiClient'

const ClientTable = ({ clients, setClients, loading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [client, setClient] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  const handleOk = async id => {
    setConfirmLoading(true)
    try {
      await apiClient.delete(`/clients/${id}`)
      const newClients = clients.filter(client => client._id !== id)
      setClients(newClients)
      setIsModalOpen(false)
    } catch (error) {
      handleCancel()
      let errorMessage = error.message
      if (error?.response?.status === 403) {
        errorMessage =
          'Revisa si tienes proyectos asociados a este cliente antes de eliminarlo.'
      }
      api.error({
        message: 'Error al eliminar el cliente',
        description: errorMessage,
        placement: 'topRight',
        duration: 3,
      })
      console.log(error)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setClient(null)
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: (text, record) => <a href={`/clients/${record._id}`}>{text}</a>,
    },
    {
      title: 'Rut',
      dataIndex: 'rut',
      key: 'rut',
    },
    {
      title: 'Eliminar',
      render: (_text, record) => (
        <Button
          type="primary"
          danger
          onClick={() => {
            setConfirmLoading(false)
            setClient(record)
            setIsModalOpen(true)
          }}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ]

  return (
    <>
      {contextHolder}
      <Table dataSource={clients} columns={columns} loading={loading} />
      <Modal
        title="Eliminar Cliente"
        open={isModalOpen}
        onOk={() => handleOk(client._id)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>¿Está seguro que desea eliminar el cliente {client?.name}?</p>
      </Modal>
    </>
  )
}

export default ClientTable
