import { Button, Form, Input, Select, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import apiClient from '../../../services/apiClient'

const UpdateForm = ({ project }) => {
  const [drimers, setDrimers] = useState([])
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const { id: projectId } = useParams()

  useEffect(() => {
    const fetchDrimers = async () => {
      const { data: rawDrimers } = await apiClient.get('/drimers')
      const drimers = rawDrimers.map(drimer => ({
        label: drimer.name,
        value: drimer._id,
      }))
      setDrimers(drimers)
    }
    fetchDrimers()
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      ...project,
      responsible: project?.responsible?._id,
      supervisor: project?.supervisor?._id,
    })
  }, [form, project])

  const onFinish = async values => {
    try {
      await apiClient.put(`/projects/${projectId}`, values)
      api.success({
        message: 'Proyecto actualizado',
        description: `El proyecto ${values.name} ha sido actualizado`,
        duration: 2,
        onClose: () => navigate('/projects'),
      })
    } catch (error) {
      console.log(error)
      api.error({
        message: 'Error al actualizar el proyecto',
        description: 'Ocurrió un error al actualizar el proyecto',
        duration: 2,
      })
    }
  }

  return (
    <>
      {contextHolder}
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Nombre" name="name">
          <Input />
        </Form.Item>

        <Form.Item label="Responsable" name="responsible">
          <Select placeholder="Selecciona un drimer" options={drimers} />
        </Form.Item>

        <Form.Item label="Supervisor" name="supervisor">
          <Select placeholder="Selecciona un drimer" options={drimers} />
        </Form.Item>

        <Form.Item label="Tipo" name="type">
          <Select>
            <Select.Option value="TIMES_AND_MATERIALS">
              Times and materials
            </Select.Option>
            <Select.Option value="FIXED_SCOPE">Fixed scope</Select.Option>
            <Select.Option value="DISCOVERY">Discovery</Select.Option>
            <Select.Option value="SUPPORT">Support</Select.Option>
            <Select.Option value="STAFFING">Staffing</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Editar proyecto
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UpdateForm
