const Home = () => {
  return (
    <div>
      <h1>Home</h1>

      <h3>¡Revisa la nueva sección de exportadores!</h3>
    </div>
  )
}

export default Home
