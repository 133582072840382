import { Button, Card, Flex, Form, Input, notification } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient from '../../services/apiClient'

const ClientCreate = () => {
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  const navigate = useNavigate()

  const onFinish = async values => {
    setLoading(true)
    try {
      await apiClient.post('/clients', values)
      setLoading(false)
      api.success({
        message: 'Cliente creado correctamente',
        description: 'Redireccionando a la lista de clientes...',
        placement: 'topRight',
        duration: 3,
        onClose: () => navigate('/clients'),
      })
    } catch (error) {
      let errorDescription = 'Intente nuevamente...'

      const apiErrors = error?.response?.data?.errors
      if (apiErrors) {
        errorDescription = apiErrors.map(error => error.message).join(' ')
      }

      api.error({
        message: 'Error al crear el cliente',
        description: errorDescription,
        placement: 'topRight',
      })
      console.error(error)
      setLoading(false)
    }
  }

  return (
    <Card>
      {contextHolder}
      <Flex align="center" justify="space-between">
        <h1>Crear Cliente</h1>
        <Button type="default" onClick={() => navigate('/clients')}>
          Volver
        </Button>
      </Flex>
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Nombre"
          name="name"
          tooltip="Nombre de fantasía del proyecto"
          required
        >
          <Input placeholder="Nombre de fantasía la empresa" />
        </Form.Item>
        <Form.Item label="Rut" name="rut" required>
          <Input placeholder="77023476" />
        </Form.Item>
        <Card type="inner" title="Contraparte de facturación">
          <Form.Item
            label="Correo Electrónico"
            name={['invoicingCounterpart', 'email']}
            required
          >
            <Input placeholder="correo@ejemplo.com" />
          </Form.Item>
          <Form.Item
            label="Nombre"
            name={['invoicingCounterpart', 'name']}
            required
          >
            <Input />
          </Form.Item>
        </Card>
        <Card
          type="inner"
          title="Contraparte de cobranza"
          style={{ marginTop: 20 }}
        >
          <Form.Item
            label="Correo Electrónico"
            name={['collectionCounterpart', 'email']}
            required
          >
            <Input placeholder="correo@ejemplo.com" />
          </Form.Item>
          <Form.Item
            label="Nombre"
            name={['collectionCounterpart', 'name']}
            required
          >
            <Input />
          </Form.Item>
        </Card>
        <Card type="inner" title="Comentario" style={{ marginTop: 20 }}>
          <Form.Item name="comment">
            <Input.TextArea />
          </Form.Item>
        </Card>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Crear
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default ClientCreate
